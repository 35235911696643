<template>
  <div class="creator-cover" v-if="creator && creator !== 'init'" :style="style">
    <div class="creatorCoverBackground"></div>
    <div :class="`creator-cover-content${$isAdmin ? ' __menu' : ''}`">
      <div class="mediaWrap">
        <img class="media" :src="creator.cover" />
      </div>
      <div class="coverInfo">
        <div class="cover">
          <div class="avatar creatorCoverAvatar">
            <UserAvatar :user="creator" size="82px" cursor="pointer" @click="UserPreview(creator)" />
          </div>
        </div>
        <div class="info">
          <div class="info-content">
            <div class="info-name">
              <div class="info-name-left">
                <p class="name creatorCoverName" capitalize>{{ creator.name }}</p>
                <p class="user">
                  <span><iconic name="mf_lock_flat" /></span>
                  <span class="nickname">@{{ creator.user }}</span>
                </p>
              </div>
              <div class="info-name-right">
                <UserNetworksWidget :user="creator" />
                <div class="options" flex>
                  <router-link class="primary-circle --fill --profile" v-if="isMyProfile()" :to="$links['my-profile']">
                    <iconic name="mf_cog" />
                  </router-link>
                  <a class="primary-circle --fill coverShareButton" @click="shareLink"><iconic name="share_nodes" /></a>
                </div>
              </div>
            </div>
            <p class="desc" v-html="$global.getShort(decodeURIComponent(creator.describe), 140)" @click="UserPreview(creator)" cursor="pointer"></p>
            <div class="item" v-if="videoCover">
              <UserLastCon :creator="creator" />
            </div>
            <div class="item userCoverInfoLocation">
              <div class="userCoverLocation">
                <span class="icon"><iconic name="location-dot" /></span>
                <span class="label country" capitalize>{{ creator.country }}</span>
              </div>
              <div class="userCoverLastCon">
                <a class="userCoverVideoBtn" v-if="videoCover" active="button" flex-center @click="MediaView(videoCover)">
                  <iconic name="play" />
                  <span>{{ $locale["watch_video"] }}</span>
                </a>
                <UserLastCon :creator="creator" v-if="!videoCover && showLastCon" />
              </div>
            </div>
            <Spacer num=".5" />
            <p class="item website display-flex">
              <span class="icon"><iconic name="link" /></span>
              <span class="label website" break-all>
                <a @click="shareLink" cursor="pointer">{{ getUserUrl() }}</a>
              </span>
            </p>
          </div>
          <CreatorFeedOptions />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CreatorFeedOptions: () => import("./CreatorFeedOptions.vue"),
    UserNetworksWidget: () => import("../widgets/UserNetworksWidget.vue"),
    UserLastCon: () => import("../user/UserLastCon.vue"),
  },
  props: ["creator"],
  methods: {
    getIniName: function() {
      return this.creator?.name?.charAt(0);
    },
    getUserUrl: function() {
      const url = `${location.protocol}//${location.host}/${this.creator.user}`;
      return url;
    },
    copyLink: function() {
      this.$global.toClipboard(this.getUserUrl(), () => {
        this.ialert.go({
          type: "notification",
          title: this.$locale["object"]["post-context"]["copy-link"],
          message: this.$locale["object"]["post-context"]["link-copied"],
        });
      });
    },
    shareLink: function() {
      this.view({
        title: `${this.$locale["share"]}`,
        component: () => import("../widgets/Share.vue"),
        outClose: true,
        class: "ShareView",
        creator: this.creator,
        animate: {
          duration: 250,
          from: {
            opacity: 0,
            transform: "scale(1.8)",
          },
          to: {
            opacity: 1,
            transform: "scale(1)",
          },
        },
      });
    },
    isMyProfile: function() {
      return this.$user && this.creator.user === this.$user.user;
    },
  },
  computed: {
    videoCover: function() {
      return this.creator.videoCover && this.creator.videoCover.src && this.creator.videoCover;
    },
    coverStyle: function() {
      return this.creator && this.creator.style && this.creator.style.cover ? this.creator.style.cover : {};
    },
    moved: function() {
      let [x, y, cWidth, cHeight] = [this.coverStyle.x, this.coverStyle.y, 510, 429];

      x = (x * 100) / cWidth;
      y = (y * 100) / cHeight;

      return { x, y };
    },
    style: function(style = "") {
      style = `--cover:url(${this.creator.cover});`;
      style += `--size:${this.size || "49px"};`;
      style += `--zoom:${Go.onlyNum(this.coverStyle.zoom) || "100"}%;`;
      style += `--x:${this.moved.x || "0"}%;`;
      style += `--y:${this.moved.y || "0"}%;`;
      return style;
    },
    showLastCon: function() {
      let dateFrom = this.creator.lastConnection;
      let dateTo = new Date(Date.now());

      const { days, hours, minutes, seconds } = this.$global.dateDiff(dateFrom, dateTo);

      if (days === 0) {
        return true;
      }

      return false;
    },
  },
  mounted: async function() {
    await this.sleep(100);
    this.scrollIntoView("#app");
  },
  beforeMount: function() {
    this.$store.state.posts.allStoriesLoaded = false;
  },
};
</script>

<style lang="scss">
$avatar_size: 82px;
.creator-cover {
  background-color: #fff;
  box-shadow: 2px 2px 6px rgba(68, 68, 68, 0.15);

  .mediaWrap {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-basis: auto;
    overflow: hidden;
  }

  .mediaWrap .media {
    object-fit: cover;
    transform: translate(var(--x), var(--y));
    width: calc(var(--zoom));
    height: auto;
    min-height: 510px;
  }

  .creator-cover-content {
    .cover {
      position: relative;
      min-height: 200px;
    }
    .avatar {
      width: $avatar_size;
      height: $avatar_size;
      position: absolute;
      left: $mpadding * 1.5;
      bottom: -$mpadding;
      .picture {
        @include Flex(inherit, center, center);
        @include BgImage();
        width: $avatar_size;
        height: $avatar_size;
        border-radius: 50%;
        font-size: 180%;
        color: #fff;
        border: solid 2px #fff;
        background-image: var(--userAvatar);
      }
      .flag {
        @include avatarFlag();
      }
    }
    .creatorCoverAvatar {
      .user-avatar {
        border: solid 2px #fff;
      }
      .userOnlineStatus {
        top: 0px;
        right: 4px;
      }
    }
    .info {
      &-content {
        padding: $mpadding * 2 $mpadding 0 $mpadding;
      }
      &-name {
        &-left {
          .name {
            font-size: 125%;
            font-family: Ubuntu Bold;
            margin: 0 0 $mpadding/2 0;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
          }
        }
        &-right {
          display: flex;
          align-items: center;
          .options {
            margin: 0 0 0 $mpadding/2;
          }
          [class*="primary-"] {
            &.--profile {
              font-size: 180%;
            }
            &:not(:last-child) {
              margin: 0 $mpadding/2 0 0;
            }
            .iconic {
              @include Flex(inherit, center, center);
            }
          }
        }
      }
      .item {
        margin: $mpadding/2 0 0 0;
        align-items: center;
        gap: $mpadding/2;
        .icon {
          .iconic {
            @include Flex(inherit, center, center);
          }
        }
        .label.website {
          color: #0094ec;
        }
      }
    }
  }

  .coverInfo {
    position: relative;
    z-index: 1;
  }

  .userCoverInfoLocation {
    display: flex;
    justify-content: space-between;
  }

  .userCoverLocation {
    display: flex;
    gap: $mpadding/2;
    align-items: center;
    justify-content: space-between;
  }

  .coverShareButton {
    font-size: 120%;
  }

  .userCoverVideoBtn {
    border: solid 1px;
    padding: $mpadding/4 $mpadding/2;
    border-radius: 2pc;
    font-size: 90%;
    cursor: pointer;
    gap: $mpadding/3;
    white-space: nowrap;
  }

  @media (min-width: $tablet_width) {
    &-content {
      max-width: $pocket_width;
      margin: 0 auto;
      .info {
        &-name {
          &-right {
            .options {
              margin: 0 0 0 $mpadding;
            }
            [class*="primary-"] {
              &:not(:last-child) {
                margin: 0 $mpadding 0 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
